import { graphql, Link } from "gatsby"
import React from "react"
// import { makeBlogPath } from "../utils"
// import dateformat from "dateformat"
import moment from "moment";
import SEO from '../components/seotagcat'
import Layout from '../components/Layout'
//import { rhythm } from '../utils/typography'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// import { SlugLink } from '../components/utils'

class PostTag extends React.Component {
  renderTags(tags) {
    tags.map((item) => {
      //console.log(item)
      return <Button variant="outline-secondary">{item}</Button>
    })
  }
  render() {
    const { data, location } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const domainName = data.site.siteMetadata.domainName
    const posts = data.privateGraphql.postbytag
    const tagg = data.privateGraphql.gettag
    const { currentPage, numPagest } = this.props.pageContext
    let curr = 1;
    if (currentPage === undefined) { curr = 1 }
    else { curr = currentPage }
    const isFirst = curr === 1
    const isLast = curr === numPagest
    const prevPage = curr - 1 === 1 ? '/' : (curr - 1).toString()
    const nextPage = (curr + 1).toString()
    const MetaTitle = (tagg.metaname !== "" && tagg.metaname !== null) ? tagg.metaname : tagg.name;
    const MetaDescription = (tagg.description !== "" && tagg.description !== null) ? tagg.description : "Description  " + tagg.name;
    const tagHeader = (tagg.displayname != "" && tagg.displayname != null) ? tagg.displayname : `${tagg.name}`
    if (location != null) {
      if (location.href != null && location.href.endsWith('/')) {
        window.location.href = window.location.href.substring(0, window.location.href.length - 1);
      }
    }
    return (
      <Layout location={this.props.location} title={MetaTitle}>
        {posts.length > 0 ?
          <SEO
            title={`${MetaTitle}`}
            description={`${tagg.count} Posts "${MetaDescription}"`}
            canonical={`https://${domainName}/${tagg.slugname}`}
            datePublished={posts.length > 0 ? posts[0].publishDate : null}
            dateModified={posts.length > 0 ? posts[0].modifiedDate : null}
            image={posts.length > 0 ? posts[0].image : ""}
          /> :
          <SEO
            title={`${MetaTitle}`}
            description={`${tagg.count} Posts "${MetaDescription}"`}
            canonical={`https://${domainName}/${tagg.slugname}`}
          />}
        <div className="col-lg-8 col-md-12">
        <div className="row">
          <header className="tb-header col-md-10 m-auto text-center">
            <h1 className="w-page-header__headline">{tagHeader}</h1>
            <p className="w-page-header__copy">
              {tagg.descriptionwithhtml ?
                <section dangerouslySetInnerHTML={{ __html: tagg.descriptionwithhtml }} />
                :
                `${tagg.count} Posts - "${MetaDescription}"`
              }
            </p>
          </header>
        </div>
        {/* <div className="w-grid__columns w-grid__columns--three">
        <h2 className="w-page-header-headline02">{tagHeader}</h2>
        </div> */}
        <div className="row">
          {posts.map(node => {
            const title = node.title || node.slugtitle
            return (
              <div className="col-md-6" key={node.slugtitle}>
                <article className="archive-post mts-post">
                  <Link to={node.slugtitle} title={title} className="post-image post-image-full">
                    <div className="featured-thumbnail">
                      <LazyLoadImage
                        className="wp-post-image"
                        alt={title}
                        title={title}
                        src={ node.image}
                        effect="blur"
                      />
                    </div>				
                    </Link>
                  <div className="post-content">
                    <div className="post-meta">
                      <span className="theauthor">
                        <Link to={`/authors/${node.profileSlugName}`}>
                          <LazyLoadImage
                            className="author-avatar"
                            title={node.profileName}
                            src={ node.profileImage}
                            effect="blur"
                          />
                          {node.profileName}
                        </Link>
                      </span>
                      <span className="thecategory">{node.categoryName !== null ? <a href={`https://${domainName}/` + node.slugCategoryName}>{node.categoryName}</a> : "คู่มือการซื้อขาย"}</span>
                      
                    </div>
                    <header>
                      <h2 className="post-title">
                      <a href={`https://${domainName}/` + node.slugtitle} title={title}>{title}</a></h2>
                    </header>
                    <div className="post-excerpt">
                      <p className="tb-post-card__subhead" dangerouslySetInnerHTML={{ __html: node.short }} />
                      <Link to={node.slugtitle} title={title} className="read-more-link">อ่านเพิ่มเติม</Link>
                    </div>
                  </div>
                </article>
              </div>
            )
          })}
        </div>
        {tagg.textwithhtml ?
          <div className="row">
            <section className="col-md-12" dangerouslySetInnerHTML={{ __html: tagg.textwithhtml }} />
          </div>
          :
          (null)}
        <div className="row pagination">
          <ul className="col d-flex justify-content-center">
            {!isFirst && (
              <li className="tb-pagination__link">
                <Link to={curr <= 2 ? `/${tagg.slugname}` : `/${tagg.slugname}/page/${prevPage}`} rel="prev">
                  ←
                </Link>
              </li>
            )}
            {Array.from({ length: numPagest }, (_, i) => {
              if (i < curr + 3 && i > curr - 3) {
                return (
                  <li
                    key={`pagination-number${i + 1}`}
                    className="w-pagination__link"
                  >
                    <Link
                      to={`/${tagg.slugname}${i === 0 ? '' : '/page/' + (i + 1)}`}
                      className={i + 1 === curr ? "current" : "page-s"}
                    >
                      {i + 1}
                    </Link>
                  </li>
                )
              }
            })}
            {!isLast && (
              <li className="tb-pagination__link">
                <Link to={`/${tagg.slugname}/page/${nextPage}`} rel="next">
                  →
                </Link>
              </li>
            )}
          </ul>
        </div>
        </div>
      </Layout>
    )
  }
}
export default PostTag
export const query = graphql`
query ($tag: String,$limit: Int = 21, $skip: Int = 0, $siteId: Int = 1066) {
  site {
    siteMetadata {
      title
      domainName
      description
    }
  }
  privateGraphql {
    gettag(slugtitle: $tag, siteId: $siteId) {
      id
      description
      image
      metaname
      name
      slugname
      descriptionwithhtml
      count
      textwithhtml
      displayname
    }
    postbytag(
      slugtitle: $tag
      limit: $limit 
      skip: $skip
      siteId: $siteId
      ) {
        id
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        modifiedDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
        profileImage
    }
  }
}
`
